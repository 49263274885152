import React, { useState } from "react";
import { Button, Alert, Card, CardGroup } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import jwt from "jwt-decode";

export const AuthorizationExample = () => {
  const { locationSampleApiOrigin, audience } = getConfig();
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    resultType: "success",
    error: null,
    accessTokenValue: ""
  });
  const [endpoint, setEndpoint] = useState("");
  var isLoading = false;

  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }
  
  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const callApi = async () => {
    try {
      isLoading = true;
      const token = await getAccessTokenSilently();
      const tokenJson = JSON.stringify(jwt(token));
      let resultTypeValue = "";
      const responseData = await fetch(`${locationSampleApiOrigin}/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(async response => {
        if (response.status >= 400) {
          resultTypeValue = "danger"
          var message = "";
          if (response.body != null) {
            message = await response.text();
          }
          isLoading = false;
          return `${response.status} : ${response.statusText} : ${message} - ${response.url}`;
        } else {
          resultTypeValue = "success"
          isLoading = false;
          return response.json();
        }
      })
          .catch(error => {
            console.log('this is the error '+ error);
            isLoading = false;
            return error;
          });
      
      setState((prevState) => ({
        ...prevState,
        showResult: true,
        apiMessage: responseData,
        resultType: resultTypeValue,
        accessTokenValue: tokenJson
      }));
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  return (
      <>
        <div className="mb-5">
          {state.error === "consent_required" && (
              <Alert color="warning">
                You need to{" "}
                <a
                    href="#/"
                    class="alert-link"
                    onClick={(e) => handle(e, handleConsent)}
                >
                  consent to get access to users api
                </a>
              </Alert>
          )}

          {state.error === "login_required" && (
              <Alert color="warning">
                You need to{" "}
                <a
                    href="#/"
                    class="alert-link"
                    onClick={(e) => handle(e, handleLoginAgain)}
                >
                  log in again
                </a>
              </Alert>
          )}
        
          <h1>Auth0 Sample API</h1>
          <p className="lead">
            Ping the Auth0 Sample API by clicking the button below.
          </p>

          <p>
            This will call the Auth0 Sample API on port 7001 that would have been started
            if you run <code>dotnet run</code> on project Auth0SampleAPI. An access token is sent as part
            of the request's `Authorization` header and the API will validate it
            using the API's audience value.</p>
          <br/>
          <br/>

          <CardGroup>
            <Card>
              <h5>Authorization</h5>
              <b>Model rules:</b>
              <li>A user with documents:read permission can read any document</li>
              <li>A user with documents:create permission can create any document</li>
              <li>A user with documents:update permission can update any document</li>
              <br/>
              <p> <button type="button" className="link-button" onClick={() => setEndpoint("documents/view/12345")}>documents/view/12345</button> : Checks if the user has documents:read permission</p>
              <p> <button type="button" className="link-button" onClick={() => setEndpoint("documents/create/456789")}>documents/create/456789</button> : Checks if user has the documents:create permission</p>
              <p> <button type="button" className="link-button" onClick={() => setEndpoint("documents/update/12345")}>documents/update/12345</button> : Checks if the user has documents:update permission and document owner ID is same as user ID</p>
              <p> <button type="button" className="link-button" onClick={() => setEndpoint("documents/update/456789")}>documents/update/456789</button> : Same as above (fakes user as document owner on BE)</p>
              <p> <button type="button" className="link-button" onClick={() => setEndpoint("documents/manage/12345")}>documents/manage/12345</button> : Checks if user has all permissions needed for "manage" action</p>
              <p> <button type="button" className="link-button" onClick={() => setEndpoint("permissions/list")}>permissions/list</button> : View my permissions</p>

            </Card>

          </CardGroup>
          <br/>
          <h4> Test Endpoints:  </h4>
          <div>  <Card><form>
            <label htmlFor="endpoint-field">
              Endpoint to Call : &nbsp;
            </label>
            <input
                id="endpoint-field"
                value={endpoint}
                onChange={(e ) => setEndpoint(e.target.value)}
            />
          </form>
          </Card></div>

          <Button
              color="primary"
              className="mt-5"
              onClick={callApi}
              disabled={!audience}
          >
            Ping API
          </Button>
        </div>
        
        <div className="result-block-container">
          {state.showResult && (
              <div className="result-block" data-testid="api-result">
                <h6 className="muted">Result</h6>
                <Alert color={state.resultType}>
                  <code>
                    <span>{JSON.stringify(state.apiMessage, null, 2)}</span>
                  </code>
                </Alert>
              </div>
          )}
        </div>
      
      </>
  );
}

export default withAuthenticationRequired(AuthorizationExample, {
  onRedirecting: () => <Loading />,
});
